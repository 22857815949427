import React, {useRef} from 'react';
import { DownloadIcon, PrintIcon } from '../Icons';
import { gql, useMutation } from '@apollo/client';
import EulaText from './EulaText';
import LoadingIndicator from '../LoadingIndicator';
import styled from 'styled-components';
import DynamicIcon from '../DynamicIcon';
import {Button} from '../ButtonStyles';
import client from '../../graphql/Apollo';
import {LoginInfo} from '@collabra/cway-frontend-common/types/__generated__/graphql';

const DocumentPanel = styled.div`
  padding: 40px;
  width: 800px;
  height: 100%;
  border-radius: 12px;
  background-color: rgba(80, 80, 80, 0.5);
  border-color: rgba(201, 76, 76, 0.3);
  display: flex;
  flex-direction: column;
`;
const ScrollWrapper = styled.div`
    flex: 1 1 auto;
    overflow-y: auto;
    min-height: 0;
`;
const Actions = styled.div`
  flex: 0 0 auto;
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ButtonWrapper = styled.div`
  margin-left: auto;
`;
const Icon = styled(DynamicIcon)`
  width: 20px;
  height: 20px;
  margin-right: 20px;
`;

const CONSENT_MUTATION = gql`
    mutation {
        consentToGeneralTerms {
            id
            acceptedTerms
        }
    }
`;
const LOGIN_INFO_QUERY = gql`
    query LoginInfoForEULA {
        loginInfo {
            id
            user {
                id
                acceptedTerms
            }
        }
    }
`

const EULA = () => {
  // ---------- Set browser tab title and meta description --------------------

  document.title = 'EULA | Cway®';
  document.querySelector('meta[name="description"]')?.setAttribute('content', 'Cway EULA page');

  // ------------------------------------------------------------------------------------------

  const [consentToGeneralTerms, { loading, error }] = useMutation(CONSENT_MUTATION);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  // Must be stored in /public folder of React app
  const eulaPath = `${process.env.PUBLIC_URL}/static/EULA.pdf`;

  if (error) return <div>Something went wrong...try again later</div>;

  return (
    <DocumentPanel>
      <ScrollWrapper>
        <EulaText />
      </ScrollWrapper>
      <Actions>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <a href={eulaPath} download><Icon tag={DownloadIcon}/></a>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <a href={eulaPath} target="_blank"><Icon tag={PrintIcon}/></a>
        <ButtonWrapper>
          <Button onClick={() => {
            consentToGeneralTerms();
            timeoutRef.current = setInterval(async() => {
              console.group('tick 500ms');
              const {data} = await client.query<{loginInfo: LoginInfo}>({query: LOGIN_INFO_QUERY, fetchPolicy: 'network-only'});
              console.log('data.user.acceptedTerms: ', data.loginInfo.user.acceptedTerms);
              if (timeoutRef.current && data.loginInfo.user.acceptedTerms) {
                console.log('Clear interval');
                clearInterval(timeoutRef.current);
              }
              console.groupEnd();
            }, 500);
          }}>
            Accept
          </Button>
        </ButtonWrapper>
      </Actions>

      {loading && <LoadingIndicator fullscreen />}
    </DocumentPanel>
  );
};
export default EULA;
